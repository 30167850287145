// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Lodash
import { isUndefined } from 'lodash';

@Pipe({
  name: 'makemepretty'
})
export class MakeMePrettyPipe implements PipeTransform {

  /**
   * Transform
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    let pretty = value;
    try {
      if (isUndefined(value) || null === value) return '';
      pretty = pretty.replace(/\-/g, '_');
      pretty = pretty.replace(/\s/g, '-');
      pretty = pretty.replace(/\//g, '&');
    } catch (error) {
      pretty = value;
    }
    return pretty;
  }
}
